


















































































































































































































































import { ScrollSpy } from '@bcase/core';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class Home extends Vue {
  public form = {
    name: '',
    company: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    token: '',
  };

  public recaptchaId = '';
  public spy?: ScrollSpy;

  public get emailAddress() {
    return webpack.CONFIG.admin.email;
  }

  public get phoneNumber(): string {
    return webpack.CONFIG.admin.phone;
  }

  public get recaptchaKey(): string {
    return this.$FEATURES.recaptcha;
  }

  async mounted() {
    if (this.recaptchaKey) {
      this.recaptchaId = (window as any).grecaptcha.render('recaptcha', {
        sitekey: this.recaptchaKey,
        callback: (token: string) => (this.form.token = token),
        'expired-callback': () => (this.form.token = ''),
      });
    }

    await new Promise(res => setTimeout(res, 500));

    const scroll = document.querySelector('main')!;
    this.spy = new ScrollSpy('bce-header > bce-nav > bce-link', { scroll });
    this.spy.detect();

    if (this.$route.hash) {
      const a = document.createElement('a');
      a.href = '/' + this.$route.hash;
      a.click();
    }
  }

  updated() {
    if (this.spy) this.spy.reload();
  }

  beforeDestroy() {
    if (this.spy) this.spy.destroy();
  }

  public error() {
    this.$bce.message(this.$t('message-not-sent'), 2);
  }

  public isVimeoUri(uri: string) {
    return uri.indexOf('vimeo.com') >= 0;
  }

  public isYouTubeUri(uri: string) {
    return uri.indexOf('youtu.be') >= 0 || uri.indexOf('youtube') >= 0;
  }

  public onCallMe() {
    if (window.innerWidth < 600) location.href = `tel:${this.phoneNumber}`;
  }

  public onMail() {
    location.href = `mailto:${this.emailAddress}`;
  }

  public onPhone() {
    location.href = `tel:${this.phoneNumber}`;
  }

  public onWhatsapp() {
    window.open(`https://api.whatsapp.com/send?phone=${this.phoneNumber}`);
  }

  public async submit() {
    const form = this.$el.querySelector('bce-form')!;

    await this.$firebase.api.mail({ ...this.form });
    if (this.recaptchaKey) (window as any).grecaptcha.reset(this.recaptchaId);
    this.$bce.message(this.$t('message-sent'), 2);
    await form.reset();
  }

  private toVimeoEmbed(uri: string) {
    const url = new URL(uri);
    const v = url.pathname.slice(1);
    return `https://player.vimeo.com/video/${v}`;
  }

  public toYouTubeEmbed(uri: string) {
    const url = new URL(uri);
    const path = url.pathname.slice(1, 100);
    const v = path === 'watch' ? url.searchParams.get('v') : path.split('?')[0];
    const t = url.searchParams.get('t') ? url.searchParams.get('t') : 0;
    return `https://www.youtube.com/embed/${v}?start=${t}`;
  }
}
